import React, { Component } from 'react'
import _get from 'lodash/get'
import { Link, graphql } from 'gatsby'
import reactStringReplace from 'react-string-replace'
import MediaQuery from 'react-responsive'
import { scroller, Element, animateScroll as scroll } from 'react-scroll';
import ImageGallery from 'react-image-gallery';

import Content from '../components/Content'
import NewsletterForm from '../components/NewsletterForm'
import Checklist from '../components/Checklist'
import Footer from '../components/Footer'
import Question from '../components/Question'
import Summary from '../components/Summary'
import RecipeGrid from '../components/RecipeGrid'
import SocialSharing from '../components/SocialSharing'

import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import './SinglePost.css'

var shuffle = require('shuffle-array');

const BeforePaginationAd = (key) =>
  <div>
  </div>

var SidebarAd = (key) =>
  <div>
    <center>
      <div data-premium="" data-adunit="MEU_CREME_BRULEE_LATERAL_01" data-sizes-desktop="[[300,600]]" >
      </div>
    </center>
  </div>

var AnchorAd = (props) =>
  <div>
    <MediaQuery maxDeviceWidth={750 - 1}>
      <AppBar position="fixed" color="primary" style={{top: 'auto', bottom: 0, backgroundColor: 'white', borderStyle: 'solid', borderWidth: '2px', borderColor: '#f3f3f3', boxShadow: '1'}}>
        <div style={{ display: 'inline-block', margin: 'auto' }}>
          <SocialSharing
            url={props.url}
            media={props.media}
          />
        </div>
      </AppBar>
    </MediaQuery>
  </div>

const PostPagination = ({ pages, currentPageIndex, currentPostURL, nextPostURL, nextPostTitle, previousPage, nextPage }) => {
  if (pages.length > 1) {
    if (currentPageIndex > 0) {
      if ((currentPageIndex+1) === pages.length) {
        return(
          // last page
          <div>
            <BeforePaginationAd key={Math.random()} />
            <div className="SinglePost--Box">
              <NewsletterForm
                name="Newsletter"
              />
            </div>
            <div className="SinglePost--Pagination">
              <button
                className="SinglePost--Pagination--Link prev"
                onClick={previousPage}
              >
                ⟵
              </button>
              {nextPostURL && (
                <Link
                  className="SinglePost--Pagination--Link post"
                  to={nextPostURL}
                >
                  {nextPostTitle}
                  <br/>
                  ⟶
                </Link>
              )}
            </div>
          </div>
        )
      } else {
        if ((currentPageIndex+1) === 10) {
          return(
            // sporadic page
            <div>
              <BeforePaginationAd key={Math.random()} />
              <div className="SinglePost--Box">
                <NewsletterForm
                  name="Newsletter"
                  headline="Você está gostando? Inscreva-se para receber conteúdos exclusivos por email."
                />
              </div>
              <div className="SinglePost--Pagination">
                <button
                  className="SinglePost--Pagination--Link prev"
                  onClick={previousPage}
                >
                  ⟵
                </button>
                <button
                  className="SinglePost--Pagination--Link next"
                  onClick={nextPage}
                >
                  ⟶
                </button>
              </div>
            </div>
          )
        } else {
          return(
            // middle page
            <div>
              <BeforePaginationAd key={Math.random()} />
              <div className="SinglePost--Pagination">
                <button
                  className="SinglePost--Pagination--Link prev"
                  onClick={previousPage}
                >
                  ⟵
                </button>
                <button
                  className="SinglePost--Pagination--Link next"
                  onClick={nextPage}
                >
                  ⟶
                </button>
              </div>
            </div>
          )
        }
      }
    } else {
      return(
        // first page
        <div>
          <BeforePaginationAd key={Math.random()} />
          <div className="SinglePost--Pagination">
            <button
              className="SinglePost--Pagination--Link next"
              onClick={nextPage}
            >
              Avançar ⟶
            </button>
          </div>
        </div>
      )
    }
  }

  return(
    // single page
    <div>
      <BeforePaginationAd key={Math.random()} />
      <div className="SinglePost--Pagination">
        {nextPostURL && (
          <Link
            className="SinglePost--Pagination--Link post"
            to={nextPostURL}
          >
            {nextPostTitle}
            <br/>
            ⟶
          </Link>
        )}
      </div>
    </div>
  )
}

class SinglePostTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = { currentPageIndex: 0 };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.restart = this.restart.bind(this);
    this.questions = this.props.questions.map((question, index) => <Question key={index} question={question} />);
  }

  changePage = (direction) => {
    this.setState({
      currentPageIndex: this.state.currentPageIndex + direction
    })
    scroll.scrollToTop({
      duration: 0,
    })
  }

  previousPage = () => {
    this.changePage(-1)
  }

  nextPage = () => {
    this.changePage(+1)
  }

  restart = () => {
    this.setState({
      currentPageIndex: 0
    })
    scroller.scrollTo('scrollToElement', {
      duration: 1500,
      delay: 50,
      smooth: true,
    })
  }

  getItem = (item, index, arr) => {
    if (index && arr[index-1] == '<li>') {
      return item;
    }
  }

  getChecklist = (item, index, arr) => {
    if (index && arr[index-1] == '<!--checklist-->') {
      item = reactStringReplace(item, "<li>", (match, i) => (
        '<li>'
      ));
      item = reactStringReplace(item, "</li>", (match, i) => (
        '</li>'
      ));
      const items = item.map(this.getItem).filter(item => (typeof item !== 'undefined'));
      return <Checklist items={items}/>
    }
    return item
  }

  getGallery = (item, index, arr) => {
    if (index && arr[index-1] == '<!--gallery-->') {
      let pictures = item.match(new RegExp('src="(.*)"', 'g')).map(text => text.replace('src="', '').replace('"', ''));
      pictures = pictures.map(picture => ({
        original: `${picture}-/resize/1200x850/`,
        thumbnail: `${picture}-/resize/240x170/`,
      }));
      return <ImageGallery key={Math.random()} items={pictures} />
    }
    return item
  }

  getIndex = () => {

  }

  componentDidMount() {
    if (sessionStorage.getItem(this.props.fields.slug) !== 'undefined') {
      this.setState({ currentPageIndex: Number(sessionStorage.getItem(this.props.fields.slug)) });
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (typeof window.ga === 'function') {
      if (this.state.currentPageIndex != prevState.currentPageIndex && this.state.currentPageIndex != 0) {
        window.ga('send', 'pageview', prevProps.fields.slug)
      };
      if (this.state.currentPageIndex + 1 == this.pagesLength) {
        window.ga('send', {hitType: 'event',
                           eventCategory: 'Quiz',
                           eventAction: 'reachLastPage',
                           eventLabel: this.props.title})
      };
    }
    sessionStorage.setItem(this.props.fields.slug, this.state.currentPageIndex);
  }

  render() {
    let { title, body, conclusion, nextPostURL, nextPostTitle, posts } = this.props;
    let { currentPageIndex } = this.state;

    let pages = [body].concat(this.questions).concat([conclusion]);
    let currentPage = pages[currentPageIndex]


    if (typeof currentPage === "string") {
      currentPage = currentPage.trim().replace("</p>", "</p><!--incontent-->");

      currentPage = reactStringReplace(currentPage, "<!--incontent-->", (match, i) => (
        "<!--incontent-->"
      ));

      currentPage = reactStringReplace(currentPage, "<!--results-->", (match, i) => (
        <Summary questions={this.questions} />
      ));

    } else {
      currentPage = [currentPage];
    };

    this.pagesLength = pages.length;

    return (
      <main>
        <article
          className="SinglePost section light"
          itemScope
          itemType="http://schema.org/BlogPosting"
        >

          <div className="container skinny">
            {(currentPageIndex + 1 == 1 && title) && (
              <h1 className="SinglePost--Title" itemProp="title">
                {title}
              </h1>
            )}
            <Element name="scrollToElement" />
            <div className="SinglePost--Content relative">
              <MediaQuery minDeviceWidth={750}>
                <Grid container spacing={2}>

                  <Grid item xs={8}>
                    <div className="SinglePost--Content relative">
                      <div className="SinglePost--InnerContent">
                        {currentPage.map(part => (typeof part === "string" ? <Content
                          source={part.trim()}
                          /> : part))}
                      </div>
                      <PostPagination pages={pages}
                                      currentPageIndex={currentPageIndex}
                                      currentPostURL={this.props.fields.slug}
                                      nextPostURL={nextPostURL}
                                      nextPostTitle={nextPostTitle}
                                      previousPage={this.previousPage}
                                      nextPage={this.nextPage} />
                    </div>

                  </Grid>
                  
                </Grid>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={750 - 1}>
                <div className="SinglePost--InnerContent">
                  {currentPage.map(part => (typeof part === "string" ? <Content
                    source={part.trim()}
                    /> : part))}
                </div>
                <PostPagination pages={pages}
                                currentPageIndex={currentPageIndex}
                                currentPostURL={this.props.fields.slug}
                                nextPostURL={nextPostURL}
                                nextPostTitle={nextPostTitle}
                                previousPage={this.previousPage}
                                nextPage={this.nextPage} />
              </MediaQuery>
              <AnchorAd
                url={this.props.fields.slug}
                media={this.props.frontmatter.featuredImage}
              />
            </div>
            <MediaQuery minDeviceWidth={750}>
              <RecipeGrid title='' tiles={posts} subject='Artigos' slug='/blog' ad={[1]} />
            </MediaQuery>
            <MediaQuery maxDeviceWidth={750 - 1}>
              <div className="container">
                <RecipeGrid title='' tiles={posts} subject='Artigos' slug='/blog' ad={[1]} />
              </div>
            </MediaQuery>
          </div>
        </article>
        {(currentPageIndex + 1 == this.pagesLength) && (
          <Footer />
        )}
      </main>
    )
  }
}


// Export Default SinglePost for front-end
const SingleQuiz = ({ data: { quiz, allQuizzes, relatedQuizzes } }) => {
  const thisEdge = allQuizzes.edges.find(edge => edge.node.id === quiz.id)

  var relatedPos = relatedQuizzes.edges.filter(edge => edge.node.frontmatter.status == 'Published');
  relatedPos = relatedPos.filter(edge => edge.node.id != quiz.id);

  return (
    <SinglePostTemplate
      {...quiz}
      {...quiz.frontmatter}
      body={quiz.html}
      nextPostURL={_get(thisEdge, 'next.fields.slug')}
      nextPostTitle={_get(thisEdge, 'next.frontmatter.title')}
      posts={shuffle.pick(relatedPos, { 'picks': 4 })}
    />
  )
}

export default SingleQuiz

export const pageQuery = graphql`
  ## Query for SingleQuiz data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleQuiz($id: String!) {
    quiz: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        date
        questions {
          question
          answers {
            answer
          }
        }
        conclusion
        categories {
          category
        }
        featuredImage
      }
      fields {
        slug
      }
    }

    allQuizzes: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "quizzes" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }

    relatedQuizzes: allMarkdownRemark(
      filter: { fields: { contentType: { in: ["posts", "quizzes"] } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            status
            categories {
              category
            }
            tags {
              tag
            }
            featuredImage
          }
        }
      }
    }
  }
`
