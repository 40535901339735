import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AdSense from 'react-adsense';
import reactStringReplace from 'react-string-replace';
import MediaQuery from 'react-responsive';
import Content from '../components/Content'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

var showdown = require('showdown');

const InContentAd = (key) => {
  return (
    <div>
      <center className="SinglePost--Ad">
        <MediaQuery minDeviceWidth={750}>
          <p className="SinglePost--AdLabel">PUBLICIDADE</p>
          <AdSense.Google
            key={Math.random()}
            client='ca-pub-2506866314573187'
            slot='9797750194'
            style={{ display: 'block', textAlign: 'center' }}
            layout='in-article'
            format='fluid'
          />
        </MediaQuery>
        <MediaQuery maxDeviceWidth={750 - 1}>
          <p className="SinglePost--AdLabel">PUBLICIDADE</p>
          <AdSense.Google
            key={Math.random()}
            client='ca-pub-2506866314573187'
            slot='9445175213'
            style={{ display: 'block' }}
            format='auto'
            responsive='true'
            layoutKey='-gw-1+2a-9x+5c'
          />
        </MediaQuery>
      </center>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    fontSize: '15px',
    fontWeight: 'bold',
    '& > span': {
      fontSize: '15px',
    },
  },
  text: {
    '& > span': {
      fontSize: '14px',
    },
  }
}));

const Question = props => {
  const { question } = props;
  const classes = useStyles();
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

  let converter = new showdown.Converter();
  let intro = converter.makeHtml(question.question.trim()).replace("</p>", "</p><!--incontent-->");

  intro = reactStringReplace(intro, "<!--incontent-->", (match, i) => (
    "<!--incontent-->"
  ));

  return (
    <div>
      {intro.map(part => (typeof part === "string" ? <Content
        source={part.trim()}
        /> : part))}
      <List>
        {question.answers.map((part, index) =>
          <ListItem>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>{letters[index]}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={part.answer} className={classes.text} />
          </ListItem>)}
      </List>
    </div>
  );
}

export default Question
